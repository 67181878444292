import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress, Snackbar} from '@material-ui/core';
import PaymentsServices from "../services/payments.service";
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(8),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const PaymentState = ({id, resourcePath}) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const [alert, setAlert] = React.useState({});
    
    const handleLoadingClose = () => {
        setLoading(false);
    };

    useEffect(() => {
        PaymentsServices.getPaymentSuccess(id)
            .then(response => {
                setLoading(false);
                const data = response.data;
                /* if(response.status == 200) {
                    if(data.result.code === '000.000.000'){
                        transactionStatus(data.merchantTransactionId, 3);
                        setAlert({severity: 'success', msg: '¡Pago realizado con éxito, su comprobante de pago se envió a su correo!'})
                    } else {
                        if(data.merchantTransactionId) {
                            transactionStatus(data.merchantTransactionId, 2);
                        }
                        setAlert({severity: 'warning', msg:((data.result.description) ? data.result.description : "")})
                    }
                } else {
                    if(data.merchantTransactionId) {
                        transactionStatus(data.merchantTransactionId, 2);
                    }
                    setAlert({severity: 'warning', msg:((data.result.description) ? data.result.description : "")})
                } */

                if(data!=null && data.result.code === '000.000.000'){
                    setAlert({severity: 'success', msg: '¡Pago realizado con éxito, su comprobante de pago se envió a su correo!'})
                } else {
                    setAlert({severity: 'warning', msg:((data.result.description) ? data.result.description : "")})
                }
                
            })
            .catch(e => {
                setLoading(false);
                setAlert({severity: 'error', msg:'Error al realizar el pago, por favor intentelo de nuevo'})
                console.log(e);
            });
    }, [id])
    
    const transactionStatus = (transactionId, status) => {
        PaymentsServices.modifyStatusTransaction(transactionId, status, new Date())
        .then(response => {
            const data = response.data;                
            sendEmail(status, data.id)
        })
        .catch(e => {
            console.log(e);
        });
    }

    const sendEmail = (status, processId) => {
        if (status === 2) {
            PaymentsServices.sendEmailCancel(processId)
            .then(response => {            
            })
            .catch(e => {
                console.log(e);
            });
        }
        if (status === 3) {
            PaymentsServices.sendEmailConfirmation(processId)
            .then(response => {            
            })
            .catch(e => {
                console.log(e);
            });
        }
    }

    return (
        <div>
            {!loading && (
                <div className={classes.root} style={{justifyContent:'center'}}>
                    <Alert severity={alert.severity}>{alert.msg}</Alert>
                </div>
            )}

            <Backdrop className={classes.backdrop} open={loading} onClick={handleLoadingClose}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
     );
}
 
export default PaymentState;