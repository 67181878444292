import React , { useEffect } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PaymentsServices from "../services/payments.service";
import NavPage from './Nav';
import PageFooter from './Footer';
import FormSelection from './FormSelection';
import { ContextEnviroment } from '../context/contextEnviroment'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  imgBank: {
    height: '80px'
  },
  divBank: {
    width: '100%',
    alignItems: 'center',
    textAlign: 'center'
  },
  
}));

export default function Index() {
  const classes = useStyles();
  const proveedor = { code_parameter: "1", parametro: "1", description: "REPRESENTA CON QUE METODO DE PAGO EN LINEA SE VA A TRABAJAR - 1 Datafast - 2 Placetopay" };
  const {
    ['parameter']: [data, setData],
  } = React.useContext(ContextEnviroment)
  

  useEffect(() => {
    /*seteo en 2 momentaneamente para poner una direccion aparte de la de datafast y puedan validar la nueva url en produccion
              cosa que despues dejo una sola con ambos metodos de pago a eleccion mediante un seleccionable en la barra superior de (DATAFAST O PLACETOPAY)
              y si es DATAFAST mandar proveedor vacio o 1 y si es placetopay mandar proveedor 2*/
              
    /*PaymentsServices.getProveedorPago()
            .then(response => {
              setData(response.data[0]);
              setData(proveedor);
            })
            .catch(e => {
                console.log(e);
            });*/
    setData(proveedor);
  }, [setData]);

  return (
    <div>
      <CssBaseline />
      <NavPage />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Pagos ágiles y eficientes
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              Completa tus datos para poder realizar el pago
            </Typography>
            {data.parametro==1 ? 
                <><Typography variant="p" align="center" color="textSecondary" paragraph>
                <strong>🚫 Las tarjetas de los siguientes bancos no aplica diferido, solo pagos corrientes: 🚫</strong>
              </Typography><div className={classes.divBank}>
                  <img className={classes.imgBank} src={window.location.origin + '/logos/banco-produbanco.jpg'} />
                  <img className={classes.imgBank} src={window.location.origin + '/logos/banco-austro.jpg'} />
                  <img className={classes.imgBank} src={window.location.origin + '/logos/banco-bolivariano.jpg'} />
                  <img className={classes.imgBank} src={window.location.origin + '/logos/banco-internacional.jpg'} />
                  <img className={classes.imgBank} src={window.location.origin + '/logos/banco-ruminahui.jpg'} />
                </div></>
            : 
              <></>
            }            

          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
      
            <Grid item  xs={12} >
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <FormSelection />
                  </CardContent>
                </Card>
              </Grid>
            
          </Grid>
        </Container>
      </main>
      {/* Footer */}
        <PageFooter />
      {/* End footer */}
    </div>
  );
}